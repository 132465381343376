import './scss/main.scss';
import { initAnchorToModal } from './ts/modules/modal/anchorToModal';
import { initCookie, initCookieDetails } from './ts/modules/cookie';
import { initDesktopMenu } from './ts/modules/desktopMenu';
import { initIndexNav } from './ts/modules/indexNav';
import { initLightbox } from './ts/modules/lightbox';
import { initMobileMenu } from './ts/modules/mobileMenu';
// import { initReadMore } from './ts/modules/readMore';
import { initQuotesAnimation, initVideoGridAnimation } from './ts/modules/animations';
import { initIntroAnimation } from './ts/modules/animations/introAnimation';
import initPageAnimations from './ts/modules/animations/pageAnimations';
import { initEmailCreator } from './ts/modules/emailCreator';
import { initCheckErrorInForm, initRepaintLayoutForm } from './ts/modules/form';
import { initGoogleMaps } from './ts/modules/googleMaps';
import { initHeroSlider } from './ts/modules/heroSlider';
import { initCheckLastElement } from './ts/modules/lastElementsMargins';
import { initMarqueeSpanTransformation } from './ts/modules/marquee';
import { initProtectedArea } from './ts/modules/protectedArea';
import { initSmoothScroll } from './ts/modules/scrollToTop';
import { initSearch } from './ts/modules/search';
import { initShare } from './ts/modules/share';
import { initSidebar } from './ts/modules/sidebar';
import { initSignature } from './ts/modules/signature';
import { initSlideshows } from './ts/modules/slideshow';
import { initTooltipTippy } from './ts/modules/tooltipTippy';
import { initVideoGrid } from './ts/modules/videogrid';
import { ParamsDebugger } from './ts/classes/paramsDebugger';
import { initHeaderAnimation } from './ts/modules/animations/headerAnimation';
import initOnlineSpendeFormular from './ts/modules/onlineSpendeFormular';
import { initCardModal } from './ts/modules/modal/cardModal';
document.addEventListener('DOMContentLoaded', () => {
    // initPaceWithoutAnimation();
    initMarqueeSpanTransformation();
    initCookie();
    initHeroSlider();
    // initReadMore();
    initSidebar();
    initCookieDetails();
    initMobileMenu();
    initDesktopMenu();
    initSmoothScroll();
    initLightbox({ selector: '.img__lightbox' });
    initLightbox({ selector: '.ce-textpic img' });
    initLightbox({ selector: '.news-textimg__img img' });
    // initHeader();
    initSearch();
    initVideoGrid();
    initQuotesAnimation();
    initVideoGridAnimation();
    initIntroAnimation();
    initShare();
    initIndexNav();
    initAnchorToModal();
    initCardModal();
    initEmailCreator('.ce-table');
    initGoogleMaps('.gmaps-wrapper');
    initCheckErrorInForm('.has-error');
    initTooltipTippy('[data-tippy-content]');
    initProtectedArea({ slugProtectedArea: '/interner-bereich' });
    initSignature();
    initPageAnimations();
    initRepaintLayoutForm();
    initCheckLastElement('.quotes');
    initSlideshows();
    initHeaderAnimation();
    initOnlineSpendeFormular();
    // ParamsDebugger.initialize({ show: true });
    const isDebugColorActive = getComputedStyle(document.documentElement)
        .getPropertyValue('--debug-color')
        .trim()
        .replace(/['"]+/g, '') || '';
    ParamsDebugger.initialize({
        show: isDebugColorActive === 'true' || isDebugColorActive === '1'
    });
});
